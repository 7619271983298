























import { Component, Vue, Watch } from "vue-property-decorator";
import CaseStudyListingWithParallax from "@/components/CaseStudies/sections/CaseStudyListingWithParallax.vue";
import { caseStudy } from "@/interfaces";
import CaseStudyTextBlock from "@/components/CaseStudies/sections/CaseStudies.TextBlock.vue";
import Summary from "@/components/CaseStudies/sections/CaseStudy.Summary.vue";

@Component({
  name: "CaseStudyDetails",
  components: {
    Summary,
    CaseStudyTextBlock,
    CaseStudyListingWithParallax
  }
})
export default class CaseStudyDetails extends Vue {
  get caseStudy(): caseStudy | null {
    return this.$store.getters.getCaseStudies.find(
      (i: caseStudy) => i.caseId === this.$route.params.case
    );
  }
  @Watch("caseStudy")
  caseStudyChanged() {
    this.$store.commit("setSiteTitle", `Projects - ${this.caseStudy?.title}`);

    this.$nextTick(() => {
      if (this.$router.currentRoute.meta.caseStudyDetail && !this.caseStudy)
        this.$router.push("/");
    });
  }
}
