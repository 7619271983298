


























import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "CaseStudyTextBlock",
  props: {
    textBlock: {}
  }
})
export default class CaseStudyTextBlock extends Vue {}
