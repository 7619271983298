<template>
  <div class="caseStudySummary" v-if="summary">
    <div class="wrapper">
      <div>
        <span>Platform</span>
        {{ summary.Platform }}
      </div>
      <div>
        <span>Release date</span>
        {{ summary.ReleaseDate }}
      </div>
      <div class="publisher">
        <span>Publisher</span>
        {{ summary.Publisher }}
      </div>
      <div class="developer">
        <span>Developer</span>
        {{ summary.Developer }}
      </div>
      <div>
        <span class="title">Social Media</span>
        <SocialLinks
          v-if="summary.SocialMedia"
          class="white"
          :twitch="summary.SocialMedia.twitch"
          :facebook="summary.SocialMedia.facebook"
          :twitter="summary.SocialMedia.twitter"
          :youtube="summary.SocialMedia.youtube"
          :discord="summary.SocialMedia.discord"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import SocialLinks from "@/components/SocialLinks";
  export default {
    name: "Summary",
    components: { SocialLinks },
    props: {
      summary: {}
    }
  };
</script>

<style scoped lang="scss">
  .caseStudySummary {
    background: black;
    color: white;
    padding: 2.5rem 0 3rem;
    height: calc(150px - 5.5rem);

    .wrapper {
      display: flex;
      justify-content: space-between;

      div {
        flex: 1;
        text-align: left;

        span {
          font-weight: 300;
          text-transform: uppercase;
          color: rgba(255, 255, 255, 0.5);
          display: block;
          font-size: 1rem;
          margin-bottom: 1.125rem;
        }

        &.publisher,
        &.developer {
          text-transform: uppercase;
        }
        &:not(:last-child) {
          margin-right: 3rem;
        }
      }
    }
  }
  @media (max-width: 1200px) {
    .caseStudySummary {
      height: auto;
      .wrapper {
        display: block;
        padding: 0 2rem;

        > div {
          &:not(:first-child) {
            margin-top: 3.5rem;
            position: relative;

            &:before {
              content: "";
              height: 2rem;
              width: 2px;
              background-color: rgba(255, 255, 255, 0.25);
              position: absolute;
              top: -2.75rem;
              left: 0.25rem;
            }
          }
          span {
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
</style>
